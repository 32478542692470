import SearchMap from './search_map';
import {handleAjaxError} from "../handle_error";

var Brand = {
  init: function () {
    Brand.start();
    Brand.changeBrandTsubo();
    Brand.changeMainBrand();
    Brand.changeColumns();
    Brand.savePreview();
    Brand.handleChangeDisplayOrder();
    Brand.handleChangePickupOrder();
    Brand.sort();
  },

  start: function () {
    $('a.add_fields.add_brand_content_block')
      .data('association-insertion-method', 'append')
      .data('association-insertion-node', '#brand_content_drag.cocoon');
    $('a.add_fields.add_brand_image')
      .data('association-insertion-method', 'append')
      .data('association-insertion-node', '#brand_image_drag.cocoon');
    $('a.add_fields.add_brand_feature_image').each( function() {
      $(this).data('association-insertion-method', 'append')
             .data('association-insertion-node', $(this).attr('data-target'));
    })
    Brand.cocoon_after_insert_callback()

    $('#brand_content_drag')
      .on('cocoon:before-insert', function (e, item) {
        item.fadeIn()
      })
      .on('cocoon:after-insert', function (e, item) {
        Brand.cocoon_after_insert_callback(item)
        Brand.initFeatureBlockEvent(item)
      })
      .on("cocoon:before-remove", function (e, item) {
        item.fadeOut()
      })
      .on("cocoon:after-remove", function (e, item) {
      });

    $('.brand_feature_image_drag')
      .on('cocoon:before-insert', function (e, item) {
        // item.fadeIn()
      })
      .on('cocoon:after-insert', function (e, item) {
        // Brand.cocoon_after_insert_callback(item)
        Brand.cocoon_after_insert_callback_feature(item)
      })
      .on("cocoon:before-remove", function (e, item) {
        // item.fadeOut()
      })
      .on("cocoon:after-remove", function (e, item) {
      });

    // NOTE event_builders.js側を使うのでコメントアウト
    // $(".btn-upload-image").bind('click', function () {
    //   $(this).parent().find(".change-image").trigger('click');
    // })

    $("#btn_add_brand_image").bind('click', function () {
      let index = 0;
      do {
        index = index + 1;
        $('.add_brand_image').click();
      } while (index < 4);
    })

    $(".btn_add_feature_image").bind('click', function () {
      let index = 0;
      do {
        index = index + 1;
        $($(this).data('target')).click();
      } while (index < 4);
    })

    $("#btn_submit_brand").bind('click', function () {
      Brand.checkValidate();
    })
  },

  cocoon_after_insert_callback: function (item = null) {
    $(".tab_right .apply a").bind('click', function (brand) {
      brand.stopPropagation();
      const parent = $(this).parents('.tab_wrapper')
      const type = $(this).data('type')
      Brand.preview_content($(this), type)

      // 表示切り替え
      parent.addClass('drag_handle')
      parent.find('.mail_edit').hide()
      parent.find('.mail_preview').fadeIn()
    })

    $(".tab_right .edit a").bind('click', function (brand) {
      brand.stopPropagation();
      const parent = $(this).parents('.tab_wrapper')
      // 表示切り替え
      parent.removeClass('drag_handle')
      parent.find('.mail_preview').hide()
      parent.find('.mail_edit').fadeIn()
    })

    $(".tab_right .cancel a").bind('click', function () {
      const parent = $(this).parents('.tab_wrapper')
      const type = $(this).data('type')

      // 表示切り替え
      parent.addClass('drag_handle')
      parent.find('.mail_edit').hide()
      parent.find('.mail_preview').fadeIn()
    })

    // sortableの並び順を定義する
    if (item && item.hasClass('sortable_list')) {
      item.parent().find(".order_no").each(function (index) {
        $(this).val(index + 1)
      });
    }
  },

  cocoon_after_insert_callback_feature: function (item = null) {
    // TODO FEATUREの画像ブロック追加時にプレビュー側にも反映
    // var list = $('.left_preview').find('.feature-block > ul.column')
    // var newItem = list.children('li.item').last()
    // newItem.find('.feature-block__image').val('');
    // newItem.find('.feature-block__title').val('');
    // newItem.find('.feature-block__description').val('');
    // list.append(newItem)
  },

  preview_content: function (brand, type) {
    const parent = brand.parents('.tab_wrapper')
    switch (type) {
      case 'brand-image':
        Brand.review_brand_image(parent);
        break;
      case 'concept_block':
        Brand.review_concept_block(parent);
        break;
      case 'feature_block':
        Brand.review_feature_block(parent);
        break;
      case 'collaboration_block':
        Brand.review_collaboration_block(parent);
        break;
      case 'vr_block':
        Brand.review_vr_block(parent);
        break;
      case 'lineup_block':
        Brand.review_lineup_block(parent);
        break;
      case 'model_house_block':
        Brand.review_model_house_block(parent);
        break;
      default:
        return
    }
  },

  review_brand_image: function (parent) {

    let old_images = parent.find('.mail_preview').find(".bi_images")
    let new_images = ''
    parent.find('.mail_edit .brand-image > ul.column').children('li.item').each(function() {
      if ($(this).css('display') == 'none') { return true; }

      new_images += `<li class="item">
        <div class="brand-image__image">
          <div class="image-prev bi_image" style="${$(this).find('.bi_image').attr('style')}"></div>
        </div>
        <div class="brand-image__caption bi_image_caption">${$(this).find('.bi_image_caption').val()}</div>
      </li>`
    });
    old_images.html(new_images);
  },

  review_concept_block: function (parent) {
    // cb_title
    let old_title = parent.find('.mail_preview').find(".cb_title")
    let new_title = parent.find('.mail_edit').find(".cb_title")
    old_title.html(new_title.val())
    console.log(old_title)
    console.log(old_title)

    // cb_body
    let old_content = parent.find('.mail_preview').find(".cb_body")
    let iframe = parent.find('iframe').contents().find('body')[0]
    let new_content = parent.find('.mail_edit').find(".cb_body")
    new_content.val($(iframe).html())
    old_content.html(new_content.val())

    // cb_image
    let old_image = parent.find('.mail_preview').find(".cb_image")
    let new_image = parent.find('.mail_edit').find(".cb_image")
    old_image.attr('style', new_image.attr("style"));
  },

  review_feature_block: function (parent) {
    // fb_title
    let old_title = parent.find('.mail_preview').find(".fb_title")
    let new_title = parent.find('.mail_edit').find(".fb_title")
    old_title.html(new_title.val())

    // fb_body
    let old_content = parent.find('.mail_preview').find(".fb_body")
    let iframe = parent.find('iframe').contents().find('body')[0]
    let new_content = parent.find('.mail_edit').find(".fb_body")
    new_content.val($(iframe).html())
    old_content.html(new_content.val())

    // fb_images
    let old_images = parent.find('.mail_preview').find(".fb_images")
    let new_images = ''
    parent.find('.mail_edit .feature-block > ul.column').children('li.item').each(function() {
      if ($(this).css('display') == 'none') { return true; }

      new_images += `<li class="item">
        <div class="feature-block__image">
          <div class="image-prev" style="${$(this).find('.fb_image').attr('style')}"></div>
        </div>
        <div class="feature-block__title">${$(this).find('.fb_image_title').val()}</div>
        <div class="feature-block__description">${$(this).find('.fb_image_description').val()}</div>
      </li>`
    });
    old_images.html(new_images);
  },

  review_collaboration_block: function (parent) {
    // clb_title
    let old_title = parent.find('.mail_preview').find(".clb_title")
    let new_title = parent.find('.mail_edit').find(".clb_title")
    old_title.html(new_title.val())

    // clb_body
    let old_content = parent.find('.mail_preview').find(".clb_body")
    console.log(old_content)
    let iframe = parent.find('iframe').contents().find('body')[0]
    console.log(iframe)
    let new_content = parent.find('.mail_edit').find(".clb_body")
    console.log(new_content)
    new_content.val($(iframe).html())
    old_content.html(new_content.val())

    let index = 0;
    do {
      index = index + 1;

      // clb_image#{i}
      let old_image = parent.find('.mail_preview').find(".clb_image" + index)
      let new_image = parent.find('.mail_edit').find(".clb_image" + index)
      old_image.attr('style', new_image.attr("style"));

      // clb_image#{i}_description
      let old_description = parent.find('.mail_preview').find(".clb_image" + index + "_description")
      let new_description = parent.find('.mail_edit').find(".clb_image" + index + "_description")
      old_description.html(new_description.val())
    } while (index < 4);
  },

  review_vr_block: function (parent) {
    // vb_title
    let old_title = parent.find('.mail_preview').find(".vb_title")
    let new_title = parent.find('.mail_edit').find(".vb_title")
    old_title.html(new_title.val())

    let index = 0;
    do {
      index = index + 1;

      // vb_image#{i}
      let old_image = parent.find('.mail_preview').find(".vb_image" + index)
      let new_image = parent.find('.mail_edit').find(".vb_image" + index)
      old_image.attr('style', new_image.attr("style"));

      // vb_image#{i}_description
      let old_description = parent.find('.mail_preview').find(".vb_image" + index + "_description")
      let new_description = parent.find('.mail_edit').find(".vb_image" + index + "_description")
      old_description.html(new_description.val())
    } while (index < 4);
  },

  review_lineup_block: function (parent) {
    // flb_title
    let old_title = parent.find('.mail_preview').find(".flb_title")
    let new_title = parent.find('.mail_edit').find(".flb_title")
    old_title.html(new_title.val())

    // flb_body
    let old_content = parent.find('.mail_preview').find(".flb_body")
    console.log(old_content)
    let iframe = parent.find('.body_1').find('iframe').contents().find('body')[0]
    console.log(iframe)
    let new_content = parent.find('.mail_edit').find(".flb_body")
    console.log(new_content)
    new_content.val($(iframe).html())
    old_content.html(new_content.val())

    // flb_body_2
    let old_content_2 = parent.find('.mail_preview').find(".flb_body_2")
    console.log(old_content_2)
    let iframe_2 = parent.find('.body_2').find('iframe').contents().find('body')[0]
    console.log(iframe_2)
    console.log(iframe)
    let new_content_2 = parent.find('.mail_edit').find(".flb_body_2")
    console.log(new_content_2)
    new_content_2.val($(iframe_2).html())
    old_content_2.html(new_content_2.val())

    // flb_image
    let old_image = parent.find('.mail_preview').find(".flb_image")
    let new_image = parent.find('.mail_edit').find(".flb_image")
    old_image.attr('style', new_image.attr("style"));
  },

  review_model_house_block: function (parent) {
    // mhb_title
    let old_title = parent.find('.mail_preview').find(".mhb_title")
    let new_title = parent.find('.mail_edit').find(".mhb_title")
    old_title.html(new_title.val())

    // mhb_body
    let old_content = parent.find('.mail_preview').find(".mhb_body")
    let iframe = parent.find('iframe').contents().find('body')[0]
    let new_content = parent.find('.mail_edit').find(".mhb_body")
    new_content.val($(iframe).html())
    old_content.html(new_content.val())

    let index = 0;
    do {
      index = index + 1;

      // mhb_image#{i}
      let old_image = parent.find('.mail_preview').find(".mhb_image" + index)
      let new_image = parent.find('.mail_edit').find(".mhb_image" + index)
      old_image.attr('style', new_image.attr("style"));

      // mhb_image#{i}_description
      let old_description = parent.find('.mail_preview').find(".mhb_image" + index + "_description")
      let new_description = parent.find('.mail_edit').find(".mhb_image" + index + "_description")
      old_description.html(new_description.val())
    } while (index < 4);
  },

  checkValidate: function () {
    let form_data = { }
    if(brand_id) {
      form_data.id = brand_id
    }
    var $form = $('#brand_form')
    var parameters = new FormData($form[0]);

    $.ajax({
      url: '/kanri/brands/ajax_check_validate',
      data: parameters,
      method: 'POST',
      processData: false, // FormData
      contentType: false, // FormData
      success: function(response) {
        if (response.errors.length != 0) {
          $('.list-error-messages span.msg').html(response.errors.join('<br>'));
          Brand.showErrorMsg();
        } else {
        }
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      },
      complete: function () {
        $('.modal-loading').modal('hide')
      }
    })
  },

  showErrorMsg: function () {
    toastr.remove()
    const erros = $('.list-error-messages').html()
    toastr.error(erros)
  },

  changeBrandTsubo: function () {
    $('#BrandTotalAreaInput').bind('change', function (e) {
      const area = $('#BrandTotalAreaInput').val()
      Brand.calcTuboPrice(null, area).done(function(response) {
        if (response.tubo_area) {
          $('#BrandTotalAreaTsubo').val(response.tubo_area)
        } else {
          $('#BrandTotalAreaTsubo').val('')
        }
      })
    })
    $('#BrandArea1fInput').bind('change', function (e) {
      const area = $('#BrandArea1fInput').val()
      Brand.calcTuboPrice(null, area).done(function(response) {
        if (response.tubo_area) {
          $('#BrandArea1fTsubo').val(response.tubo_area)
        } else {
          $('#BrandArea1fTsubo').val('')
        }
      })
    })
    $('#BrandArea2fInput').bind('change', function (e) {
      const area = $('#BrandArea2fInput').val()
      Brand.calcTuboPrice(null, area).done(function(response) {
        if (response.tubo_area) {
          $('#BrandArea2fTsubo').val(response.tubo_area)
        } else {
          $('#BrandArea2fTsubo').val('')
        }
      })
    })
    $('#BrandArea3fInput').bind('change', function (e) {
      const area = $('#BrandArea3fInput').val()
      Brand.calcTuboPrice(null, area).done(function(response) {
        if (response.tubo_area) {
          $('#BrandArea3fTsubo').val(response.tubo_area)
        } else {
          $('#BrandArea3fTsubo').val('')
        }
      })
    })
  },

  calcTuboPrice: function (price, land_area) {    
    return $.ajax({
      url: '/kanri/sales/base/calc_tubo_price',
      data: { price: price, land_area: land_area },
      method: 'get'
    });
  },

  changeMainBrand: function () {
    $("#brand_kind").change(function() {
      if($(this).val() == 'option_brand') {
        $("#select_main_brand").show();
      } else {
        $("#brand_main_brand_id").val('')
        $("#select_main_brand").hide();
      }
    });
  },

  changeColumns: function () {
    $("#brand_layout").change(function() {
      if($(this).val() == 7) {
        $("#brand_layout_other").show();
      } else {
        $("#brand_layout_other").val('')
        $("#brand_layout_other").hide();
      }
    });

    $("#brand_floor").change(function() {
      if($(this).val() == 4) {
        $("#brand_floor_other").show();
      } else {
        $("#brand_floor_other").val('')
        $("#brand_floor_other").hide();
      }
    });

    $("#brand_roof_shape").change(function() {
      if($(this).val() == 4) {
        $("#brand_roof_shape_other").show();
      } else {
        $("#brand_roof_shape_other").val('')
        $("#brand_roof_shape_other").hide();
      }
    });
  },

  savePreview: function () {
    $('.btn-edit-brand-preview').on('click', function () {
      $("#brand_save_action").val('save-preview')
      $("#btn_submit_brand").trigger('click')
    })
  },
  handleChangeDisplayOrder: function () {
    $('.brand-display-sortable').on('sortupdate', function () {
      var brand_ids = $('.brand-display-sortable > tr').map(function(i, e) {
        return $(e).data('brand-id')
      }).get().join(',')
      $('#brand_ids_change_display_order').val(brand_ids)
    })
    $('.show-betsudai-loading.brand').bind('click', function(){
      $('#change_display_order').modal('hide')
    })
    $('.brand-display-sortable').sortable({placeholder: "placeholder"});
  },
  handleChangePickupOrder: function () {
    $('.brand-pickup-sortable').on('sortupdate', function () {
      var brand_ids = $('.brand-pickup-sortable > tr').map(function(i, e) {
        return $(e).data('brand-id')
      }).get().join(',')
      $('#brand_ids_change_pickup_order').val(brand_ids)
    })
    $('.show-betsudai-loading.brand').bind('click', function(){
      $('#change_pickup_order').modal('hide')
    })
    $('.brand-pickup-sortable').sortable({placeholder: "placeholder"});
  },

  initFeatureBlockEvent: function(item) {
    if ($(item).find('.feature-block').length == 0) return;
    // MEMO: 4 は cocoon によって生成されるランダムIDの class の位置
    // refs: https://github.com/IJGN/betsudai_admin_web/pull/1937#discussion_r1348443758
    let id = $(item).find('input').filter(':first').attr('id').split('_')[4];
    $(item).find('#brand_feature_image_drag_').attr('id',`brand_feature_image_drag_${id}`);

    $(item).find('#btn_add_feature_image_').attr({
      "id": `btn_add_feature_image_${id}`,
      "data-target": `#add_brand_feature_image_${id}`
    }).bind('click', function () {
      let index = 0;
      do {
        index = index + 1;
        $($(this).attr('data-target')).click();
      } while (index < 4);
    });

    let target =  `#brand_feature_image_drag_${id}`;

    $(item).find('#add_brand_feature_image_').attr({
      "id": `add_brand_feature_image_${id}`,
      "data-target": `#brand_feature_image_drag_${id}`
    }).data('association-insertion-method', 'append')
      .data('association-insertion-node', target);
  },
  // NOTE: event_builder.js を使用
  // openTabPreview: function () {
  // }

  sort: function () {
    $('.table-brand').on('click', '.brand-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      const data = { order: order, sort_by: sort_by }
      const data_string = Object.keys(data).map(function(key) {
        return key + '=' + data[key]
      }).join('&');

      $('.modal-loading').modal('show');
      return $.ajax({
        url: '/kanri/brands/search',
        data: data,
        method: 'get',
        success: function () {
          $('.modal-loading').modal('hide');
        }
      });
    })
  },
}

export default Brand;
